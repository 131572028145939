@use 'assets/styles/utils/helper' as h;
@use './theme.tokens' as t;

.save {
  position: relative;
  display: flex;
  align-items: center;

  .button {
    font-size: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    appearance: none;
    background-color: transparent;
    border: none;
    padding: 0;
    margin: var(--save--margin);
    height: h.rem(44px);
    min-width: h.rem(44px);
  }

  .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: var(--save-icon--width);
    font-size: var(--save-icon--font-size);
    color: var(--save-icon--color);
    margin: h.rem(0 12px);

    &.iconSave {
      display: var(--save-icon-save--display);
    }

    &.iconSaveAlt {
      display: var(--save-icon-save-alt--display);
    }

    &.iconSaveHover {
      display: none;
    }

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        opacity: var(--save-icon-hover--opacity);
      }
    }
  }

  .navbarPlacement {
    margin: h.rem(0 24px 0 0);
  }

  .text {
    margin: var(--save-text--margin);
    font-family: var(--save-text--font-family);
    font-size: var(--save-text--font-size);
    line-height: var(--save-text--line-height);
    text-transform: var(--save-text--text-transform);
    color: var(--save-text--color);
    display: var(--save-text--display);

    > a {
      color: var(--save-text-link--color);
      text-decoration: underline;
    }
  }

  .tooltip {
    display: var(--save-tooltip--display);
    background: var(--save-tooltip--background);
    border: var(--save-tooltip--border);
    font-family: var(--founders-cond);
    width: h.rem(190px);
    height: h.rem(65px);
    font-weight: 600;
    font-size: h.rem(16px);
    line-height: 100%;
    padding: h.rem(14px);
    letter-spacing: initial;
    border-radius: h.rem(6px);
    position: absolute;
    z-index: 1;
    pointer-events: none;
    top: var(--save-tooltip--top);
    transform: var(--save-tooltip--transform);
    left: var(--save-tooltip--left);
    color: var(--save-tooltip--color);

    > span {
      text-transform: unset;
    }

    &::before {
      content: '';
      position: absolute;
      top: var(--save-tooltip-before--top);
      left: var(--save-tooltip-before--left);
      transform: var(--save-tooltip-before--transform);
      display: block;
      width: 0;
      height: 0;
      border: h.rem(11px) solid transparent;
      border-color: var(--save-tooltip-before--border-color);
    }

    &::after {
      content: '';
      position: absolute;
      margin-left: h.rem(-5px);
      border-width: h.rem(10px);
      border-style: solid;
      top: var(--save-tooltip-after--top);
      left: var(--save-tooltip-after--left);
      transform: var(--save-tooltip-after--transform);
      border-color: var(--save-tooltip-after--border-color);
    }
  }

  &.isSaved {
    .icon {
      color: var(--save-isSaved-icon--color);
    }

    .iconSave {
      display: var(--save-isSaved-icon-save--display);
      color: var(--save-isSaved-icon-save--color);
    }

    .iconSaveAlt {
      display: var(--save-isSaved-icon-save-alt--display);
    }

    .tooltip {
      display: none;
    }
  }

  &.isAuthenticated {
    .icon {
      color: var(--save-isAuthenticated-icon--color);
    }

    .iconSaveAlt {
      color: var(--save-isAuthenticated-icon-save-alt--color);
    }

    .tooltip {
      width: h.rem(125px);
    }
  }

  &:not(.isInitialized) {
    display: var(--save-icon--uninitialized--display);

    .button {
      cursor: var(--save-isNotInitialized-button--cursor);

      .text {
        display: none;
      }
    }

    .icon {
      color: var(--save-isNotInitialized-icon--color);
    }
  }

  &.isThumbnail {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 0;
    width: 50px;
    border-bottom: 40px solid var(--save-isThumbnail--border-bottom-color);
    border-right: 10px solid transparent;
    z-index: 2;
    box-sizing: border-box;
    align-items: flex-start;

    :global(.related-item__default) & {
      border-bottom-color: var(--grey-20);
    }

    .button {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      margin: 0;
    }

    @mixin offset {
      left: #{h.rem(-18px)};
      bottom: #{h.rem(-16px)};
    }

    &.offset-s {
      @include offset;
    }

    @include h.breakpoint-s-only {
      &.offset-s-only {
        @include offset;
      }
    }

    @include h.breakpoint-m {
      &.offset-m {
        @include offset;
      }
    }

    // Add 1px ot the bottom of the button to cover rotated image on hover
    :global(.teaserow-content-item) & {
      box-shadow: #{h.rem(0 1px)} var(--save-thumb-shadow-color);
    }

    // Add 3px to the left of the button to cover rotated image on hover
    :global(.tease-card__picture) &,
    :global(.wide-tease-item__wrapper) & {
      box-shadow: #{h.rem(-3px 0)} var(--save-thumb-shadow-color);
    }
  }

  &.isTitle {
    align-self: flex-start;
    margin-top: #{h.rem(16px)};
    margin-left: #{h.rem(8px)};
    margin-right: #{h.rem(-12px)};
    z-index: 10;

    :global(.pkg-flex-grid-first-col) & {
      margin-right: #{h.rem(-12px)};
    }

    :global(.related-item__no-art) & {
      margin-right: #{h.rem(28px)};
    }
  }

  &:not(.isInitialized) .icon:hover {
    opacity: 1;
  }

  :global(body.news) & {
    &.isAuthenticated {
      button {
        .icon:hover {
          opacity: 1;
        }
      }
    }

    @include h.breakpoint-m {
      &.isAuthenticated {
        button {
          &:hover {
            .iconSave {
              display: flex;
            }

            .iconSaveAlt {
              display: none;
            }
          }
        }
      }

      &.isAuthenticated.isSaved {
        button {
          &:hover {
            .iconSave {
              display: none;
            }

            .iconSaveAlt {
              display: flex;
            }
          }
        }
      }
    }
  }

  :global(body.today) & {
    &.isInitialized:not(.disableHover) {
      @media (hover: hover) and (pointer: fine) {
        button:hover {
          .iconSaveHover {
            display: flex;
          }

          .icon.iconSave,
          .icon.iconSaveAlt {
            display: none;
          }
        }
      }
    }
  }

  :global(body.today .video-details__social-container) & {
    margin-left: var(--save-icon-save--margin-left);
  }
}

.ctaWrapper {
  position: absolute;
  bottom: 0;
  left: 0;
}
