@use 'assets/styles/utils/helper' as h;

.saveCTAContainer {
  position: relative;
  width: h.rem(131px);
  height: h.rem(40px);
  background-color: var(--secondary-color);
  left: h.rem(40px);
  white-space: nowrap;
  overflow: hidden;
  transform-origin: left;
  will-change: transform;
  z-index: 1;
}

.saveCTAContainer.animate {
  animation: slide 5s forwards;
}

.saveCTAContainer.offset {
  bottom: h.rem(-16px);
  left: h.rem(16px);
}

.message {
  color: var(--white);
  font-family: var(--secondary-font);
  margin: 0;
  margin-left: h.rem(20px);
  padding-top: h.rem(11px);
  font-size: h.rem(16px);
  line-height: h.rem(17.5px);
  text-decoration: underline;
  text-underline-offset: h.rem(4px);

  &:hover {
    color: #fffad1;
    text-decoration-color: var(--accent-orange);
  }
}

@keyframes slide {
  0% {
    transform: scaleX(0);
  }

  10%,
  90% {
    transform: scaleX(1);
  }

  100% {
    transform: scaleX(0);
  }
}
