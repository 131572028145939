@use 'assets/styles/utils/helper' as h;

.container {
  font-family: var(--publico-txt);
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: auto;
  position: relative;
  z-index: h.get-z-index('container');
  // hotfix to address Chrome 84+ scroll position lock bug
  // https://support.google.com/chrome/thread/62861011?hl=en
  overflow-anchor: none;
}

* {
  box-sizing: border-box;
}

body,
html {
  margin: 0;
  padding: 0;
  max-width: 100vw;
}

@media print {
  html,
  body {
    max-width: none;
  }
}

body {
  overflow-x: hidden;

  &:global(.xfinity) {
    #XPNV_header_wrapper {
      position: sticky !important;
      top: 0;
    }
  }
}

body:global(.disable-vertical-scroll) {
  overflow-y: hidden;
  width: 100%;
  height: 100%;
}

body:global(.isIOS) {
  position: fixed;
}

body:global(.select) .container {
  font-family: Lato, Arial, sans-serif;
}

picture {
  font-size: 0;

  img {
    width: 100%;
  }
}

a {
  text-decoration: none;
  color: inherit;
  outline: none;
}

ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

:global(.hidden) {
  display: none;
}
