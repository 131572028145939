@use 'assets/styles/utils/helper' as h;
@use './theme.tokens.scss' as t;

.typeicon {
  pointer-events: none;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--typeicon-color);
  width: h.rem(30px);
  height: h.rem(30px);
  background: var(--typeicon-background);
  left: auto;
  font-size: 6px;
  line-height: 1;

  @include h.breakpoint-x {
    width: h.rem(32px);
    height: h.rem(32px);
  }

  &__radius {
    left: var(--typeicon-radius-position-left);
    bottom: var(--typeicon-radius-position-bottom);
  }

  &.icon-video.pointer-events-initial {
    pointer-events: initial;
  }
}
