.placeholder {
  position: relative;
  z-index: 1;
}

.r100,
.r50,
.r56 {
  width: 100%;
}

// 1x1
.r100 {
  padding-top: 100%;
}

// 2x1
.r50 {
  padding-top: 50%;
}

// 16x9
.r56 {
  padding-top: 56.25%;
}
