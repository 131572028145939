@use 'assets/styles/utils/helper' as h;

.save {
  --save-icon-save--margin-left: 0;
  --save-icon--color: var(--white);
  --save-icon--width: #{h.rem(20px)};
  --save-icon-save--display: none;
  --save-icon-save-alt--display: flex;
  --save-isNotInitialized-icon--color: #ccc;
  --save-tooltip--display: none;
  --save-isSaved-icon--color: var(--secondary-color);
  --save-isAuthenticated-icon-save-alt--color: var(--secondary-color);
  --save-isAuthenticated-icon--color: var(--secondary-color);
  --save-text--margin: 0;
  --save-text-link--color: inherit;
  --save-tooltip--top: 50%;
  --save-tooltip--left: calc(100% + 20px);
  --save-tooltip--transform: translateY(-50%);
  --save-tooltip--background: var(--grey-30);
  --save-tooltip--border: 1px solid var(--grey-40);
  --save-tooltip--color: #102039;
  --save-tooltip-before--border-color: transparent var(--grey-40) transparent transparent;
  --save-tooltip-before--transform: translateY(-50%) translateX(-100%);
  --save-tooltip-before--top: 50%;
  --save-tooltip-before--left: 0;
  --save-tooltip-after--border-color: transparent var(--grey-30) transparent transparent;
  --save-tooltip-after--transform: translateY(-50%) translateX(-100%);
  --save-tooltip-after--top: calc(50%);
  --save-tooltip-after--left: 5px;
  --save-icon-hover--opacity: 1;
  --save-isNotInitialized-button--cursor: wait;
  --save-icon--uninitialized--display: block;
  --save--margin: #{h.rem(-12px 0)};
  --save-thumb-shadow-color: var(--white);

  :global(body.news) & {
    --save-icon--width: #{h.rem(16px)};
    --save-icon--font-size: #{h.rem(16px)};
    --save-text--font-family: var(--founders-mono);
    --save-text--font-size: var(--text-12);
    --save-text--text-transform: uppercase;
    --save-text--line-height: #{h.rem(20px)};
    --save-text--color: #2a2a36;
    --save-icon--color: var(--grey-50);
    --save-icon-save--display: none;
    --save-icon-save-alt--display: flex;
    --save-isAuthenticated-icon-save-alt--color: var(--blue-40);
    --save-isAuthenticated-icon--color: var(--blue-40);
    --save-isSaved-icon-save--display: flex;
    --save-isSaved-icon-save-alt--display: none;
    --save-isSaved-icon-save--color: var(--blue-40);
    --save-text-link--color: var(--blue-40);
    --save-icon-hover--opacity: 0.7;
    --save-isNotInitialized-button--cursor: not-allowed;

    @include h.breakpoint-m {
      --save-icon--width: #{h.rem(20px)};
      --save-icon--font-size: #{h.rem(20px)};
      --save-tooltip--display: block;
    }
  }

  :global(body.today) & {
    --save--margin: #{h.rem(-2px 0)};
    --save--border-left: #{h.rem(1px)} solid var(--grey-50);
    --save-icon--font-size: #{h.rem(20px)};
    --save-icon--color: var(--secondary-color);
    --save-text--margin: #{h.rem(0 0 0 8px)};
    --save-text--font-family: var(--secondary-font);
    --save-text--font-size: #{h.rem(12px)};
    --save-text--text-transform: uppercase;
    --save-text--line-height: #{h.rem(20px)};
    --save-text--color: var(--secondary-color);
    --save-isAuthenticated-text--display: none;
    --save-isSaved-icon-save--display: flex;
    --save-isSaved-icon-save--color: var(--secondary-color);
    --save-isSaved-icon-save-alt--display: none;
    --save-isThumbnail--border-bottom-color: var(--white);
    --save-icon--uninitialized--display: none;
    --save-text--display: none;
  }

  :global(body.today.personPage) & {
    --person-feed-background-color: var(--white);
  }

  :global(body.news.videoPage) & {
    --save-isAuthenticated-icon-save-alt--color: var(--grey-50);
    --save-text--color: var(--grey-50);
    --save-text-link--color: #82aeff;
    --save-tooltip--top: calc(100% + 20px);
    --save-tooltip--left: #{h.rem(59px)};
    --save-tooltip--transform: translateX(-50%);
    --save-tooltip--background: var(--grey-60);
    --save-tooltip--border: 1px solid var(--grey-50);
    --save-tooltip--color: var(--white);
    --save-tooltip-before--border-color: transparent transparent var(--grey-50) transparent;
    --save-tooltip-before--transform: translateX(-50%);
    --save-tooltip-before--top: -34%;
    --save-tooltip-before--left: 50%;
    --save-tooltip-after--border-color: transparent transparent var(--grey-60) transparent;
    --save-tooltip-after--transform: translateX(-25%);
    --save-tooltip-after--top: calc(-34% + 2px);
    --save-tooltip-after--left: 50%;
  }

  :global(body.today.videoPage) & {
    --save-icon-save--margin-left: #{h.rem(10px)};
    --save-isAuthenticated-icon-save-alt--color: var(--secondary-30);
    --save-isSaved-icon-save--color: var(--secondary-30);
    --save-icon--color: var(--secondary-30);
    --save-isThumbnail--border-bottom-color: var(--black);

    @include h.breakpoint-l {
      --save-icon-save--margin-left: 0;
    }
  }
}
