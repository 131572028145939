@use 'assets/styles/utils/helper' as h;

:root {
  --typeicon-background: var(--default-color);
  --typeicon-color: var(--white);
  --typeicon-radius-position-left: auto;
  --typeicon-radius-position-bottom: auto;
}

.today {
  --typeicon-radius-position-left: -4px;
  --typeicon-radius-position-bottom: -4px;
}
